import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormbuilderValidationService } from 'src/app/services/formbuilder-validation.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage ,NotificationType } from 'src/app/entity/NotificationMessage';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-password-type',
  templateUrl: './password-type.component.html',
  styleUrls: ['./password-type.component.scss'],
})
export class PasswordTypeComponent extends FieldType implements OnInit {
  matcher = new MyErrorStateMatcher();

  pattern: string | RegExp;
  fieldName: any;
  searchValue: any;
  tickets_id: string;
  loader: boolean;
  urlIdentifiant: any;
  alerts: any;
  buttons: any;
  constructor(private validationService: FormbuilderValidationService,

    private notificationsService: NotificationsService,
    private translate : TranslateService,
    private routes: Router,
    private route: ActivatedRoute,
    
  ) {
    super();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    this.fieldName = this.field.key || `password_${Math.random().toString(36).substr(2, 9)}`;
    this.setValidators();

    // Initialize form control value if needed
    this.searchValue=this.field.templateOptions?.autofill
    this.urlIdentifiant =this.field.templateOptions?.urlIdentifiant 
    if (this.field.templateOptions?.autofill) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
    if (this.formControl.value) {
      this.formControl.setValue(this.formControl.value);
    }

    // Update Formly form control value on changes
    this.formControl.valueChanges.subscribe((value) => {
      this.emitValidationStatus();
    });
    this.emitValidationStatus();
  }

  private setValidators(): void {
    const { minLength, maxLength, required, pattern } = this.field.templateOptions;
    

    const validators = [];
    if (pattern) {
      this.pattern = pattern;
      validators.push(this.patternValidator(pattern));
    }

    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  private emitValidationStatus(): void {
    const isValid = this.formControl.disabled || this.formControl.valid;
    this.validationService.updateValidationStatus(this.fieldName, isValid,this.field.templateOptions?.label);
  }

  updateValue(event: Event): void {
    const currentRoute = this.routes.url;
    if (currentRoute.includes('ticketsandsupport')) {
      this.route.paramMap.subscribe((params) => {
        this.tickets_id = params.get('tickets_id');
        this.loader=true
        this.validationService.getAllInfoTicket(this.tickets_id).subscribe(
          (response) => {
            this.loader=false
            if (this.urlIdentifiant) {
              this.loader=true
              const bodyTicket=response
              this.validationService.consumeAutoFillInputs(this.urlIdentifiant , bodyTicket).subscribe(
                (response) => {
                  this.loader=false
                    this.formControl.setValue(response.response.value);
                    const notificationMessage = new NotificationMessage();
                    notificationMessage.type = NotificationType.success;
                    notificationMessage.title = this.alerts.Successoperation;
                    notificationMessage.message = response?.message;
                    this.notificationsService.sendMessage(notificationMessage);
                },
                (error) => {
                  this.loader=false
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.error;
                notificationMessage.title = this.alerts.Operationechoue;
                notificationMessage.message = error?.message?.message;
                this.notificationsService.sendMessage(notificationMessage);
                }
              );
            } else {
              this.loader=false
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = this.alerts.Operationechoue;
              notificationMessage.message = this.alerts.urlServiceOblig;
              this.notificationsService.sendMessage(notificationMessage);
            }
             
          },
          (error) => {
          this.loader=false
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = error?.message?.message;
          this.notificationsService.sendMessage(notificationMessage);
          }
        );

      });
    }


    
    }

  private patternValidator(pattern: string | RegExp): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      if (pattern && !new RegExp(pattern).test(control.value)) {
        return { pattern: true };
      }
      return null;
    };
  }
}

<div class="form-group">
    <mat-form-field class="full-width">
      <mat-label>{{ field.templateOptions?.label }}</mat-label>
      <input
        matInput
        type="number"
        name="number"
        [formControl]="numberFormControl"
        [errorStateMatcher]="matcher"
        [formlyAttributes]="field"
        placeholder="{{ field.templateOptions?.placeholder || 'Enter a number' }}"
        [attr.disabled]="field.templateOptions?.autofill ? true : null" 
      />
      <button mat-icon-button  type="button" *ngIf="searchValue" matSuffix (click)="updateValue()">
        <mat-icon *ngIf="!loader" >refresh</mat-icon>
        <mat-spinner *ngIf="loader" [diameter]="15"></mat-spinner>
      </button>
      <div class="description-wrapper" *ngIf="field.templateOptions?.description">
        <span class="description-text">{{ field.templateOptions.description }}</span>
      </div>
     
      <mat-error *ngIf="numberFormControl.hasError('required')">
        {{ 'This field is required' }}
      </mat-error>
      <mat-error *ngIf="numberFormControl.hasError('pattern')">
        {{ 'languages.formbuilderTransaltion.textRegex' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';
export class Reassurance {
  Config_Premium_Reas_Part: number;
    Config_Premium_Reas_Limit: number;
    Config_Claim_Reas_Limit: number;
    Config_Claim_Settlement: string='';
    Config_Premium_Part_Amount: number;
    Calculated_Premium_Reas_Part: number;
    Calculated_Claim_Reas_Part: number;
    Config_Premium_Deductible_Amount: number;
    Config_Claim_Reas_Limit_out_of_deductible: number;
    Config_Claim_Reas_Absolut_Limit: number;
    Calculated_Premium_Reas_Excess: number;
    Config_Trigger_Claims_Amount_Sum: number;
    Config_Claims_Amount_Sum_Limit: number;
    Config_Claims_Periodicity: number;
    code_garantie:string='';
    id_gamme:string='';
    Reassureur:string='';
    type: string='';
}
export class TypeAssureur {
id:number;
type:string;
}
@Component({
  selector: 'app-modal-reassurance',
  templateUrl: './modal-reassurance.component.html',
  styleUrls: ['./modal-reassurance.component.css']
})

export class ModalReassuranceComponent implements OnInit {
  show_loading_adding: boolean;
  list_type:TypeAssureur[];
  Organismes: OrganismeListe[] = [];
  reassurance:Reassurance = new Reassurance();
  alerts: any;
  buttons: any;
  show_loading_add: boolean;
  mode:string;
  constructor(
   
    public dialogRef: MatDialogRef<ModalReassuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiGroupGarantieService: ApiGroupGarantieService,
    private apiGarantieService: ApiGarantieService,
    private apiCampagniesService: ApiCampagniesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
  
  }
  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
   
      });});
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      
       
      });
    this.reassurance.code_garantie=this.data.idGarantie;
    this.reassurance.id_gamme=this.data.idGamme;
    this.mode=this.data.mode;
    this.listeReassuranceTypes();
    this. getListOrganisme();
  }
  onTypeSelect() {
    console.log('Selected Type ID:', this.reassurance.type);
  }
  AddReassureur(){
   if(this.mode=='edit'){
    const unsubscribeInsertGarantie = this.apiGarantieService.updateTypeReassurance(this.reassurance.toString()).subscribe(
      (Response) => {
        
        this.toastr.success("this.alerts.reassuranceAdd");
        this.show_loading_add = false;
      
      },
      (err) => {
        this.toastr.warning("this.alerts.reassuranceNotAdd");
        this.show_loading_add = false;
   
      }
    );
 
  }else{

    const unsubscribeInsertGarantie = this.apiGarantieService.addTypeReassurance(this.reassurance).subscribe(
      (Response) => {
        
        this.toastr.success("this.alerts.reassuranceAdd");
        this.show_loading_add = false;
      
      },
      (err) => {
        this.toastr.warning("this.alerts.reassuranceNotAdd");
        this.show_loading_add = false;
   
      }
    );
  }
  }

  



  listeReassuranceTypes() {
    this.apiGarantieService.getListReassuranceTypes().subscribe((data) => {
      this.list_type = data;
      this.reassurance.type = this.list_type[0]?.id.toString();
    });
  }

   getListOrganisme() {
    const unsubscribeGetOrganismes = this.apiCampagniesService.getOrganismes().subscribe(
      (Response: OrganismeListe[]) => {
      
        this.Organismes = Response;
      }
    );
  }
}


import { DatePipe } from '@angular/common';
import swal from 'sweetalert2';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { map, startWith, tap } from 'rxjs/operators';
import { ApiPaymentService } from 'src/app/services/ApiPayment/api-payment.service';
import{HistoryServiceTicket} from 'src/app/pages/tickets-and-support/history.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AbstractControl, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from './../../../../services/utils/utils.service';
import { Frais } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';

@Component({
  selector: 'app-frais',
  templateUrl: './frais.component.html',
  styleUrls: ['./frais.component.css']
})
export class FraisComponent implements OnInit {

  @ViewChild('closeModal') private closeModal: ElementRef;
  @ViewChild('matRe') matRef: MatSelect;
  @ViewChild('Modepayemnt') Modepayemnt: MatSelect;
 
  allPaiement: any[] = [];
  frais: Frais[] = [];
  statutFrais: any[] = [];
  typeFrais: any[] = [];
  fraisInsert: Frais = new Frais();
  show_loading: boolean = false;
  form: FormGroup;
  show_loading_adding: boolean = false;
  list_invalid: any[] = [];
  list_invalid_string: string = '';
  ListeUnsbscribeWebService: Subscription[] = [];
  typefrais: string
  // @Output() paimentfraisexport: EventEmitter<any> = new EventEmitter();
  buttons: any;
  alerts: any;
  country = environment.country;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;
  @Input() ticket_id: string;

  // @ViewChild('type') typeControl: NgForm;
  @ViewChild('status') statusControl: NgForm;

  @ViewChild('montant') montantControl: NgForm;

  @ViewChild('taxe') taxeControl: NgForm;
  @ViewChild('Modepayemnt') ModepayemntControl: NgForm;

  generateQuitanceLoader: { [key: string]: boolean } = {};
  disabledTaxe: boolean =false;
  selectedTypeFrais: any;
  constructor(
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private apiAffairesService: ApiAffairesService,
    private apiPaiementService: ApiPaymentService,
    private datePipe: DatePipe,
    private apiTicketsServices: ApiTicketsService,
    private HistoryServiceTicket: HistoryServiceTicket,
    private cdRef : ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.ListeUnsbscribeWebService.forEach((element) => {
      element?.unsubscribe();
    });
  }



  filteredTypeFrais$: Observable<any[]> = of([]);
 
  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });
    this.filteredTypeFrais$ = this.typeControl.valueChanges.pipe(
      startWith(''), // Start with empty value
      map(value => this.filterTypeFrais( value))
    );

    this.getInformationsContrat();
    this.getModePaiement();
    this.getAllTypeFrais();
    this.getAllStatutFrais();
    
 

  }




  alertSuccess(data) {
    swal.fire({
      title: this.alerts.Successoperation,
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: this.alerts.error,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
      
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: this.alerts.badHappened,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  convertCurreny(type, event) {
    if (type === 'montant') {
      this.fraisInsert.montant = UtilsService.formatValue(event.target.value.toString(), this.currency);
    }
  }

  // generateQuitance(id_frais) {
  //   this.generateQuitanceLoader[id_frais] = true;
  //   this.apiAffairesService.generateQuitance(this.ticket_id, id_frais).subscribe(
  //     (data: any) => {
  //       this.generateQuitanceLoader[id_frais] = false;
  //       const fileLink = data.url_file;
  //       if (fileLink) {
  //         window.open(fileLink, '_blank');
  //       }
  //     }, 
  //     (error) => {
  //       this.generateQuitanceLoader[id_frais] = false;
  //       const notificationMessage = new NotificationMessage();
  //       notificationMessage.type = NotificationType.error;
  //       notificationMessage.title = this.alerts.Operationechoue;
  //       notificationMessage.message = error.error.message;
  //       this.notificationsService.sendMessage(notificationMessage);
  //     }
  //   );
  // }

  getInformationsContrat() {
    this.show_loading = false;
    const unsubscribeGetfraisPayementAffaire = this.apiTicketsServices
      .getfraisPayementTicket(this.ticket_id)
      .subscribe((data) => {
        this.frais = data;
        this.show_loading = true;
        // this.paimentfraisexport.emit(data);
      });
    this.ListeUnsbscribeWebService.push(unsubscribeGetfraisPayementAffaire);
  }

  getModePaiement() {
    const unsubscribeGetModePaiement = this.apiPaiementService
      .GetModePaiement()
      .pipe(
        tap(({ data }) => {
          this.allPaiement = data;
        })
      )
      .subscribe();
    this.ListeUnsbscribeWebService.push(unsubscribeGetModePaiement);
  }


  filterTypeFrais(value): any[] {
    value=value.type_frais ||value || ''
    const filterValue = value.toLowerCase();
    return this.typeFrais.filter(type =>
      type.type_frais.toLowerCase().includes(filterValue)
    );
  }

  

  getAllStatutFrais() {
    const unsubscribeallStatutFrais = this.apiAffairesService.allStatutFrais().subscribe((data: any) => {
      this.show_loading = true;
      this.statutFrais = data;
    });
    this.ListeUnsbscribeWebService.push(unsubscribeallStatutFrais);
  }

  getAllTypeFrais() {
    const unsubscribeAllTypeFrais = this.apiAffairesService.allTypeFraisTicket().subscribe((data: any) => {
      this.typeFrais = data;
      this.filteredTypeFrais$ = of(this.typeFrais);
      this.filteredTypeFrais$ = this.typeControl.valueChanges.pipe(
        startWith(''),
        map(value => this.filterTypeFrais(value))
      );
    });
    this.ListeUnsbscribeWebService.push(unsubscribeAllTypeFrais);
  }

  checkObligData(){
    if( !this.fraisInsert.montant || !this.fraisInsert.id_frais ||  !this.fraisInsert.taxe|| (this.fraisInsert.statut==="c4ca4238a0b923820dcc509a6f75849b" && !this.fraisInsert.id_mode_paiement)){

      return false
    }else {
      return true 
    }
  }

  typeControl = new FormControl('',  {
    validators: [autocompleteObjectValidator()],
  });

  public displayContactFn(typefrais?: any): string | undefined {
    this.typefrais = typefrais.type_frais;
    return  this.typefrais 
  }
  

  onTypeSelected(selectedType: any) {
    // Set fraisInsert.id_frais and fraisInsert.tax based on selected type
    this.fraisInsert.id_frais = selectedType.id;
    this.fraisInsert.taxe = selectedType.taxe; // Assuming `tax` is available in your data
    this.disabledTaxe=true
    // this.typeControl.setValue(selectedType.type_frais, { emitEvent: false });

  // Reset filteredTypeFrais to show all options
  // this.filteredTypeFrais$ = of([...this.typeFrais]);
  }



  addFrais(valid, directive) {
    this.makeTouched()
    // this.list_invalid = directive.filter((element) => element.control.status === 'INVALID');
    if (!valid || !this.checkObligData()) {
      this.alertWarning(this.alerts.fillObligInputs);
    } else {

      
        this.show_loading_adding = true;
        const unsubscribeInsertFrais = this.apiTicketsServices.InsertFrais(this.ticket_id, this.fraisInsert).subscribe(
          (response) => {
            this.show_loading_adding = false;
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.success;
            notificationMessage.title = this.alerts.Successoperation;
            notificationMessage.message = response.message;
            this.notificationsService.sendMessage(notificationMessage);
            this.getInformationsContrat();
           this.emptyFrais();
           this.HistoryServiceTicket.sendFetchDataSignal(true)
           this.apiTicketsServices.triggerReloadTransaction();
            this.closeModal.nativeElement.click();
          },
          (error) => {
            this.show_loading_adding = false;
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.Operationechoue;
            notificationMessage.message = error;
            this.notificationsService.sendMessage(notificationMessage);
          }
        );
        this.ListeUnsbscribeWebService.push(unsubscribeInsertFrais);
      
    }
  }

  // clear() {
  //   this.matRef.options.forEach((data: MatOption) => data.deselect());
  //   this.Modepayemnt.options.forEach((data: MatOption) => data.deselect());
  //   this.status.options.forEach((data: MatOption) => data.deselect());
  // }

  
  
 
  makeTouched(){

    const makeTouchedControl = (control: AbstractControl) => {
      if (control) {
        control.markAsTouched();
        control.updateValueAndValidity();

      }
    };
  
    // makeTouchedControl(this.typeControl?.control);
    makeTouchedControl(this.statusControl?.control);

    makeTouchedControl(this.montantControl?.control)
    makeTouchedControl(this.taxeControl?.control);
    makeTouchedControl(this.ModepayemntControl?.control);
  }

  
  emptyFrais() {
    this.fraisInsert = new Frais();
    

    const resetControl = (control: AbstractControl) => {
      if (control) {
        control.reset('', { emitEvent: false });
        control.markAsPristine();
        control.markAsUntouched();
        control.setErrors(null);
        // control.updateValueAndValidity({ onlySelf: true });
        
      }
    };
  
    if (this.typeControl) {
      this.typeControl.setValue(''); // Clear the value
      this.typeControl.markAsPristine(); // Mark as pristine
      this.typeControl.markAsUntouched(); // Mark as untouched
      this.typeControl.setErrors(null); // Clear errors
    }
    resetControl(this.statusControl?.control);

    resetControl(this.montantControl?.control)
    resetControl(this.taxeControl?.control);
    resetControl(this.ModepayemntControl?.control);
   
  }
  

  expandName(name: string): string {
    name = name?.trim();
    if (name?.length < 25) {
      return name;
    } else {
      return name.substring(0, 24) + '...';
    }
  }
}

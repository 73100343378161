export class TransactionFinanciere {
    
 type : string =""
 date: string= "";
    motif : string= "";
    Montant: string= "";
    detail : any={};
    file: any = "";
    mode_Payment : any=""
    fileName: string =""
    mode_paiement: string =""
  }
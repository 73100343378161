import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormbuilderValidationService } from './../../../services/formbuilder-validation.service';
import { NotificationsService} from 'src/app/shared/NotificationsService/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessage ,NotificationType } from 'src/app/entity/NotificationMessage';
import { ActivatedRoute, Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-number-type',
  templateUrl: './number-type.component.html',
  styleUrls: ['./number-type.component.scss'],
})
export class NumberTypeComponent extends FieldType implements OnInit {
  numberFormControl = new FormControl('', [
    Validators.pattern('^-?[0-9]+$'), // Default number pattern
  ]);

  matcher = new MyErrorStateMatcher();
  fieldName: any;
  searchValue: any;
  urlIdentifiant: any;
  loader: boolean;
  alerts: any;
  buttons: any;
  tickets_id: any;

  constructor(private validationService: FormbuilderValidationService,
    private notificationsService: NotificationsService,
    private translate : TranslateService,
    private routes: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  
  ngOnInit(): void {

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });

    this.fieldName = this.field.key || `numberField_${Math.random().toString(36).substr(2, 9)}`;
    const pattern:any  = this.field.templateOptions?.pattern || '';
    this.urlIdentifiant =this.field.templateOptions?.urlIdentifiant 
    

    this.numberFormControl.setValidators([Validators.pattern(pattern), this.numberPatternValidator(pattern)]);
    this.searchValue=this.field.templateOptions?.autofill
    if (this.field.templateOptions?.autofill) {
      this.numberFormControl.disable();
    } else {
      this.numberFormControl.enable();
    }
    // Initialize form control value if needed
    if (this.formControl.value) {
      this.numberFormControl.setValue(this.formControl.value);
    }

    // Update Formly form control value on changes
    this.numberFormControl.valueChanges.subscribe((value) => {
      this.formControl.setValue(value);
      this.emitValidationStatus();
    });
    this.emitValidationStatus();
  }

  private emitValidationStatus(): void {
    const isValid = this.numberFormControl.disabled || this.numberFormControl.valid;
    this.validationService.updateValidationStatus(this.fieldName, isValid, this.field.templateOptions?.label);
  }

 
  updateValue(event: Event): void {
    const currentRoute = this.routes.url;
    if (currentRoute.includes('ticketsandsupport')) {
      this.route.paramMap.subscribe((params) => {
        this.tickets_id = params.get('tickets_id');
        this.loader=true
        this.validationService.getAllInfoTicket(this.tickets_id).subscribe(
          (response) => {
            this.loader=false
            if (this.urlIdentifiant) {
              this.loader=true
              const bodyTicket=response
              this.validationService.consumeAutoFillInputs(this.urlIdentifiant , bodyTicket).subscribe(
                (response) => {
                  this.loader=false
                  
                    this.numberFormControl.setValue(parseFloat(response.response.value));
                    const notificationMessage = new NotificationMessage();
                    notificationMessage.type = NotificationType.success;
                    notificationMessage.title = this.alerts.Successoperation;
                    notificationMessage.message = response?.message;
                    this.notificationsService.sendMessage(notificationMessage);
                },
                (error) => {
                  this.loader=false
                const notificationMessage = new NotificationMessage();
                notificationMessage.type = NotificationType.error;
                notificationMessage.title = this.alerts.Operationechoue;
                notificationMessage.message = error?.message?.message;
                this.notificationsService.sendMessage(notificationMessage);
                }
              );
            } else {
              this.loader=false
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = this.alerts.Operationechoue;
              notificationMessage.message = this.alerts.urlServiceOblig;
              this.notificationsService.sendMessage(notificationMessage);
            }
             
          },
          (error) => {
          this.loader=false
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = error?.message?.message;
          this.notificationsService.sendMessage(notificationMessage);
          }
        );

      });
    }


    
    }



  private numberPatternValidator(pattern: string | RegExp): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = new RegExp(pattern);
      if (control.value && !regex.test(control.value)) {
        return { pattern: true };
      }
      return null;
    };
  }
}

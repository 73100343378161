import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from './configuration';
import { BASE_PATH } from './variables';
import { CustomHttpUrlEncodingCodec } from './encoder';

@Injectable({
  providedIn: 'root',
})
export class FormbuilderValidationService {
  private fieldValidityMap: { [key: string]: { isValid: boolean; label: string } } = {};
  private isFormValidSubject = new BehaviorSubject<boolean>(true);
  private invalidFieldsSubject = new BehaviorSubject<string[]>([]); 
  isFormValid$ = this.isFormValidSubject.asObservable();
  invalidFields$ = this.invalidFieldsSubject.asObservable();
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  
  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  updateValidationStatus(fieldName: string, isValid: boolean , label:string): void {

    this.fieldValidityMap[fieldName] = { isValid, label };

    this.evaluateFormValidity(); 
  }

  
  private evaluateFormValidity(): void {
    
    const allFieldsValid = Object.values(this.fieldValidityMap).every((field) => field.isValid);
    console.log(this.fieldValidityMap);

    this.isFormValidSubject.next(allFieldsValid);
    const invalidFields = this.getInvalidFields();
    this.invalidFieldsSubject.next(invalidFields);
  }

 
  getFieldValidity(fieldName: string): boolean {
    return this.fieldValidityMap[fieldName]?.isValid ?? true;
  }

  getInvalidFields(): string[] {
    return Object.values(this.fieldValidityMap)
      .filter((field) => !field.isValid) // Filter only invalid fields
      .map((field) => field.label); // Return the labels of invalid fields
  }

  resetFieldValidity(): void {
    this.fieldValidityMap = {}; 
    console.log(this.fieldValidityMap);
    this.isFormValidSubject.next(true); 
    this.invalidFieldsSubject.next([]); 
  }


  public getAllInfoTicket(idticket: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idticket === null || idticket === undefined) {
      throw new Error('Required parameter idticket was null or undefined when calling listCommunicationDaeObjectifGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
   

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_all_infos_ticket/${idticket}?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  public consumeAutoFillInputs(urlIdentifiant: string, body, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (urlIdentifiant === null || urlIdentifiant === undefined) {
      throw new Error('Required parameter urlIdentifiant was null or undefined when calling listCommunicationDaeObjectifGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
   

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${urlIdentifiant}?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: body,
      }
    );
  }

}

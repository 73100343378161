<ng-container appearance="standard" class="p-3 w-70">
    <form class="example-form">
        <div class="shadow-box-card-body ">
            <div class=" row col-md-4">
                <mat-form-field class="full-width">
                    <ng-container *ngIf="!loaderListGarantie">
                        <span class="spinner-style position-absolute">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </span>
                    </ng-container>
                    <mat-label>
                        {{'languages.garantie.addGarantie' | translate}}
                    </mat-label>
                    <input type="text" matInput [matAutocomplete]="auto" [formControl]="searchAddgarantie">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="onSelectGarantie($event )">
                        <mat-option *ngFor="let item of searchgaranties| async; let index = index" [value]="item">
                            {{item.nom}} ({{ item.code}})
                        </mat-option>
                    </mat-autocomplete>
                    <i matSuffix class="fa-solid fa-magnifying-glass"
                        style="color: rgb(110, 108, 108);padding-right:10px"></i>


                </mat-form-field>
            </div>
            <div class="table-responsive p-3">
                <!-- *ngIf="ListG.length> 0 || listgroup.length> 0 " -->
                <table class="table" style="table-layout: fixed;
                width: 100%;" *ngIf="ListG.length> 0 || listgroup.length> 0 ">
                    <thead class="head-table">
                        <tr>
                            <th>#</th>
                            <th> {{'languages.listing.icon' | translate}}</th>
                            <th> {{'languages.affaire.name' | translate}}</th>
                            <th> {{'languages.affaire.code' | translate}}</th>

                            <th> {{'languages.garantie.Ageminimale' | translate}}</th>
                            <th>{{'languages.garantie.Agemaximale' | translate}}</th>
                            <th> {{'languages.opportunite.label_groupe' | translate}}</th>
                            <th> {{'languages.listing.Obligatoire' | translate}}</th>
                            <th> {{'languages.garantie.reassurance' | translate}}</th>

                            <th> {{'languages.listing.Action' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let i of ListG; let index = index">
                            <td>{{ index+1 }}</td>
                            <td *ngIf="i.icon_garantie !== null && i.icon_garantie !== '' ">
                                <i class=" {{i?.icon_garantie}} fa-lg"
                                    *ngIf="i.icon_garantie !== null && i.icon_garantie !== ''"></i>

                            </td>
                            <td *ngIf="i.icon_garantie == null || i.icon_garantie == '' ">-</td>
                            <td>{{i.nom_garantie ? i.nom_garantie :"-"}}</td>
                            <td>{{i.code_garantie}} </td>

                            <td>
                                <mat-form-field appearance="standard" class="md-block" class="customWidth">
                                    <input matInput type="text" (keyup)="valuechangeH($event, 'age_min' ,index )"
                                        (keypress)="numberOnly($event)" [value]="i.age_min ? i.age_min : ''">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block" class="customWidth">
                                    <input matInput type="text" (keyup)="valuechangeH($event, 'age_max' ,index )"
                                        (keypress)="numberOnly($event)" [value]="i.age_max ? i.age_max : ''">
                                </mat-form-field>
                            </td>

                            <td>
                                <mat-form-field appearance="standard" class="customWidth">
                                    <mat-label>{{'languages.garantie.chooseGroup' | translate}}</mat-label>
                                    <mat-select name="id_groupe{{index}}" id="id_groupe{{index}}"
                                        [(ngModel)]="i.id_groupe"
                                        (selectionChange)="onSelectGroup($event , i ,index, 'p')" required>
                                        <mat-option name="{{i.id_groupe}}" id="{{i.id_groupe}}"
                                            *ngFor="let item of garantie; let index = index" [value]="item.id">
                                            {{item.libelle}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </td>
                            <td>
                                <label class="switch mt-3">
                                    <input type="checkbox" name="obligatoire" [checked]="i.obligatoire == 1"
                                        (change)="isActive($event ,i)">
                                    <span class="slider round"></span>
                                </label>

                            </td>
                            <td>-</td>
                        </tr>
                        <tr *ngFor="let i of listgroup; let index = index">
                            <td>{{ListG.length + index+1 }}</td>
                            <td *ngIf="i.icon_garantie !== null && i.icon_garantie !== ''  ">
                                <i class=" {{i?.icon_garantie}} fa-lg "
                                    *ngIf="i.icon_garantie !== null && i.icon_garantie !== '' "></i>

                            </td>
                            <td *ngIf="i.icon_garantie == null || i.icon_garantie == ''  ">-</td>
                            <td>{{i.nom_garantie ? i.nom_garantie :"-"}}</td>
                            <td>{{ i.code_garantie }} </td>
                            <td>
                                <mat-form-field appearance="standard" class="customWidth">
                                    <input matInput type="text" (keypress)="numberOnly($event)"
                                        (keyup)="valuechange($event, 'age_min' ,index )"
                                        [value]="i.age_min ? i.age_min : ''">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="customWidth">
                                    <input matInput type="text" (keypress)="numberOnly($event)"
                                        [value]="i.age_max ? i.age_max : ''"
                                        (keyup)="valuechange($event, 'age_max' ,index )">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="customWidth">
                                    <mat-label> {{'languages.garantie.chooseGroup' | translate}}</mat-label>
                                    <mat-select name="{{i.code_garantie}}{{index}}" id="{{i.code_garantie}}{{index}}"
                                        (selectionChange)="onSelectGroup($event , i ,index , 'A')"
                                        [(ngModel)]="i.id_groupe" required>
                                        <mat-option *ngFor="let item of garantie; let index = index" [value]="item.id">
                                            {{item.libelle}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <label class="switch mt-2">
                                    <input type="checkbox" name="obligatoire" [checked]="i.obligatoire == 1"
                                        (change)="isActive($event ,i)">
                                    <span class="slider round"></span>
                                </label>
                            </td>

                            <td>
                                <label class="switch mt-2">
                                    <input type="checkbox" (click)="openModal(i.code_garantie)">
                                    <span class="slider round"></span>
                                </label>
                            </td>

                        


                            <td class="d-flex justify-content-center">

                                <button type="button" class="btn-dt-delete mt-3"
                                    (click)="getGarantieInfo(i.code, index)">
                                    <i class="fa-solid fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="text-center" *ngIf="ListG.length < 1 && listgroup.length < 1">
                    {{'languages.garantie.noGaranties' | translate}}
                </p>
            </div>
        </div>
    </form>
    
</ng-container>

<div >
  
        <div class="modal-header">
            <h2 class="text-center title justify-content-center m-0 w-100"
                id="exampleModalLabel">{{'languages.garantie.addReassurance' |
                translate}}</h2>
            <button #closeModal type="button" class="close" data-dismiss="modal"
                aria-label="Close">

            </button>
        </div>
        <div class="modal-body">

            <div class="conatainer-modal-elements">
                <div class="row">
                    <div class="col-6 p-2">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>{{'languages.garantie.selectTypeReinsurance' | translate}}</mat-label>
                            <mat-select
                              placeholder="{{'languages.garantie.selectTypeReinsurance' | translate}}"
                              [(ngModel)]="reassurance.type" 
                              name="type">
                              <mat-option *ngFor="let type of list_type" [value]="type.id.toString()">
                                {{type.type}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                      

                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label >{{'languages.garantie.reassureur' | translate}}</mat-label>
                        
                            <mat-select [(ngModel)]="reassurance.Reassureur" name="organisme">
                                <mat-option *ngFor="let Organisme of Organismes" [value]="Organisme.id">
                                    {{ Organisme.nom }}
                                </mat-option>
                            </mat-select>
                            <!-- <mat-error *ngIf="organisme?.invalid && (organisme?.dirty || organisme?.touched)">
                                <mat-error *ngIf="organisme.errors.required">
                                   {{'languages.user.obligOrganisme' | translate}}
                                </mat-error>
                            </mat-error> -->
                        </mat-form-field>
                       

                        <mat-form-field class="w-100" *ngIf="reassurance.type=='11'">
                            <mat-label>{{"languages.garantie.Partreassureur" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Config_Premium_Reas_Part"
                                placeholder="{{'languages.garantie.Partreassureur' | translate}}">
                        </mat-form-field>

                       
                        <mat-form-field class="w-100"  >
                            <mat-label>{{"languages.garantie.montantSinistre" |
                                translate}}</mat-label>
                            <input matInput [(ngModel)]="reassurance.Calculated_Claim_Reas_Part"
                                placeholder="{{'languages.garantie.montantSinistre' | translate}}">
                        </mat-form-field>

                        <mat-form-field class="w-100" *ngIf="reassurance.type=='5' || reassurance.type=='2'">
                            <mat-label>
                                {{"languages.garantie.primePerte" |
                                translate}}</mat-label>
                            <input matInput [(ngModel)]="reassurance.Calculated_Premium_Reas_Excess"
                                placeholder="{{'languages.garantie.primePerte' | translate}}">
                        </mat-form-field>

                      
                        
                 
                      
                    </div>
                    <div class="col-6 p-2">
                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='11'">
                            <mat-label>{{"languages.garantie.montantPrime" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Calculated_Premium_Reas_Part"
                                placeholder="{{'languages.garantie.montantPrime' | translate}}">
                        </mat-form-field>

                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='8'">
                            <mat-label>{{"languages.garantie.MontantExcedentaire" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Calculated_Premium_Reas_Part"
                                placeholder="{{'languages.garantie.MontantExcedentaire' | translate}}">
                        </mat-form-field>

                        <mat-form-field class="w-100">
                            <mat-label>{{ "languages.garantie.ModalitésRèglementSinistres" | translate }}</mat-label>
                            <mat-select [(ngModel)]="reassurance.Config_Claim_Settlement">
                              <mat-option value="direct">{{ "Direct" | translate }}</mat-option>
                              <mat-option value="assureur">{{ "Assureur" | translate }}</mat-option>
                            </mat-select>
                          </mat-form-field>

                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='11'">
                            <mat-label>{{"languages.garantie.plafondPrime" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Config_Premium_Reas_Limit"
                                placeholder="{{'languages.garantie.plafondPrime' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='11'">
                            <mat-label>{{"languages.garantie.plafondSinistre" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Config_Claim_Reas_Limit"
                                placeholder="{{'languages.garantie.plafondSinistre' | translate}}">
                        </mat-form-field>

                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='2'">
                            <mat-label>{{"languages.garantie.plafondIntervention" |
                                translate}}</mat-label>
                            <input matInput [(ngModel)]="reassurance.Config_Claim_Reas_Limit_out_of_deductible"
                                placeholder="{{'languages.garantie.plafondIntervention' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='2'">
                            <mat-label>
                                {{"languages.garantie.MontantMaxSinistreCouvert" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Config_Claim_Reas_Absolut_Limit"
                                placeholder="{{'languages.garantie.MontantMaxSinistreCouvert' | translate}}">
                        </mat-form-field>

                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='5'">
                            <mat-label>{{"languages.garantie.seuilActivation" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Config_Trigger_Claims_Amount_Sum"
                                placeholder="{{'languages.garantie.seuilActivation' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='5'" >
                            <mat-label>{{"languages.garantie.PériodeaActivation" |
                                translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Config_Claims_Periodicity"
                                placeholder="{{'languages.garantie.PériodeaActivation' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='5'">
                            <mat-label>{{"languages.garantie.MontantMaxPrisEnCharge"
                                | translate}}</mat-label>
                            <input matInput  [(ngModel)]="reassurance.Config_Trigger_Claims_Amount_Sum"
                                placeholder="{{'languages.garantie.MontantMaxPrisEnCharge' | translate}}">
                        </mat-form-field>

                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='8'">
                            <mat-label>{{"languages.garantie.plein"
                                | translate}}</mat-label>
                            <input matInput [(ngModel)]="reassurance.Config_Premium_Part_Amount "
                                placeholder="{{'languages.garantie.plein' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="w-100"  *ngIf="reassurance.type=='2'">
                            <mat-label>{{"languages.garantie.montantFranchise" |
                                translate}}</mat-label>
                            <input matInput
                            [(ngModel)]="reassurance.Config_Premium_Deductible_Amount" placeholder="{{'languages.garantie.montantFranchise' | translate}}">
                        </mat-form-field>
                    </div>

                </div>



            </div>
        </div>

        <div class="row d-flex justify-content-center w-100 mt-2 mb-3">
            <div class="col-6">
                <ng-container *ngIf="show_loading_adding">
                    <div class="row mb-3 mt-3">
                        <div class="text-center m-auto ">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row text-center justify-content-center w-100">
                <button type="button" class="btn-dt-save-popup mr-2" (click)="AddReassureur()">
                    <i
                        class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>
                    {{'languages.buttons.ajouter' | translate}}
                </button>
                <button data-dismiss="modal" aria-label="Close" type="button"
                    class="btn-dt-cancel-popup ml-2" #closeModal>
                    <i
                        class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                    {{'languages.buttons.annuler' | translate}}

                </button>
            </div>
        </div>


</div>

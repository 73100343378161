import { JsonFormly } from './../../../entity/Formbuilder/JsonFormly';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import swal from 'sweetalert2';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { PasswordFormly } from 'src/app/entity/Formbuilder/PasswordFormly';
import { ArrayFormly } from 'src/app/entity/Formbuilder/ArrayFormly';
import { CheckboxFormly } from 'src/app/entity/Formbuilder/CheckboxFormly';
import { DateFormly } from 'src/app/entity/Formbuilder/DateFormly';
import { EmailFormly } from 'src/app/entity/Formbuilder/EmailFormly';
import { FormField } from 'src/app/entity/Formbuilder/FormField';
import { FormModel } from 'src/app/entity/Formbuilder/FormModel';
import { MultiselectFormly } from 'src/app/entity/Formbuilder/MultiselectFormly';
import { NullObjectFormly } from 'src/app/entity/Formbuilder/NullObjectFormly';
import { NumberFormly } from 'src/app/entity/Formbuilder/NumberFormly';
import { RadioFormly } from 'src/app/entity/Formbuilder/RadioFormly';
import { SelectFormly } from 'src/app/entity/Formbuilder/SelectFormly';
import { TextAreaFormly } from 'src/app/entity/Formbuilder/TextAreaFormly';
import { TextFormly } from 'src/app/entity/Formbuilder/TextFormly';
import { MonetiqueFormly } from 'src/app/entity/Formbuilder/monetiqueFormly';
import { TimeFormly } from 'src/app/entity/Formbuilder/TimeFormly';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'shared-formProduit-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.css'],
})
export class SharedFormBuilderComponent implements OnInit, OnChanges {
  public editorOptions: JsonEditorOptions;
  public data: any;
  model: any = {};
  dict = {
    Select: 'selectionner',
    Time: 'temps',
    Text: 'Texte',
    'Text Area': 'Zone de texte',
    Radio: 'Radio',
    Password: 'Mot de passe',
    Number: 'Nombre',
    'Null Element': 'Element nul',
    Email: 'E-mail',
    Date: 'Date',
    'Check box': 'Case a cocher',
    Array: 'Tableau',
    'Multi Select': 'Selection multiple',
    Json:'Json',
    monetique:'Monetique',
  };

  open: boolean;
  target: any = Event;
  showclose: boolean = false;
  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  @Output() formbuilder;
  @Input() formBuilderData: any;
  @Input() parentObject?: any;
  @Output() dataformbuilder = new EventEmitter<any>();
  @Output() itemerror = new EventEmitter<boolean>();
  obligText: any = true;
  toDropElements: FormField[] = [];
  toggle = false;
  formModel: FormModel = new FormModel();
  fields: FormlyFieldConfig[];
  form = new FormGroup({});

  formModelCopie: FormModel = new FormModel();
  fieldsCopie: FormlyFieldConfig[];
  formCopie = new FormGroup({});

  dropZoneCreatedModel: FormField[] = [];
  dropZoneCreatedModelCopie: FormField[] = [];

  toggle_view = false;
  formbuilderTransaltion: any;

  constructor(
    private apiAdminBpmServices: ApiAdminBpmService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formlyJsonschema: FormlyJsonschema,
    private translate: TranslateService
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
    this.editorOptions.expandAll = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    /* if (!this.apiAdminBpmServices.sharedIsSubmited) {
      this.ngOnInit();
    } */
    if (changes.parentObject) {
      if (this.parentObject !== undefined) {
        this.parentObject?.schema?.properties?.forEach((element) => {
          element.disabled = true;
        });
      }

      const listField = [];
      if (this.parentObject?.schema?.properties?.length > 0)
        this.parentObject?.schema?.properties?.forEach((element) => {
          listField.push(element);
        });
      if (this.formBuilderData?.schema?.properties?.length > 0)
        this.formBuilderData?.schema?.properties?.forEach((element) => {
          listField.push(element);
        });
      if (listField.length > 0) {
        this.formBuilderData.schema.properties = listField;
        this.onChangeJson(this.formBuilderData.schema);
      }
    }
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.formbuilderTransaltion = object.formbuilderTransaltion;
        this.dict = {
          Select: this.formbuilderTransaltion.selectionner,
          Time: this.formbuilderTransaltion.temps,
          Text:  this.formbuilderTransaltion.Texte,
          'Text Area':  this.formbuilderTransaltion.texteArea,
          Radio: this.formbuilderTransaltion.Radio,
          Password: this.formbuilderTransaltion.password,
          Number: this.formbuilderTransaltion.Nombre,
          'Null Element': this.formbuilderTransaltion.NullElement,
          Email: this.formbuilderTransaltion.Email, 
          Date: this.formbuilderTransaltion.Date,
          'Check box': this.formbuilderTransaltion.checkBox,
          Array: this.formbuilderTransaltion.Tableau,
          'Multi Select': this.formbuilderTransaltion.MultiSelect,
          Json: this.formbuilderTransaltion.Json,
          monetique:this.formbuilderTransaltion.monetique,
        };
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.formbuilderTransaltion = object.formbuilderTransaltion;
        this.dict = {
          Select: this.formbuilderTransaltion.selectionner,
          Time: this.formbuilderTransaltion.temps,
          Text:  this.formbuilderTransaltion.Texte,
          'Text Area':  this.formbuilderTransaltion.texteArea,
          Radio: this.formbuilderTransaltion.Radio,
          Password: this.formbuilderTransaltion.password,
          Number: this.formbuilderTransaltion.Nombre,
          'Null Element': this.formbuilderTransaltion.NullElement,
          Email: this.formbuilderTransaltion.Email, 
          Date: this.formbuilderTransaltion.Date,
          'Check box': this.formbuilderTransaltion.checkBox,
          Array: this.formbuilderTransaltion.Tableau,
          'Multi Select': this.formbuilderTransaltion.MultiSelect,
          Json: this.formbuilderTransaltion.Json,
          monetique:this.formbuilderTransaltion.monetique,
        };
      });
    this.apiAdminBpmServices.sharedIsSubmited = false;
    if (this.parentObject === undefined) {
      if (
        (this.formBuilderData != null || this.formBuilderData !== undefined) &&
        this.formBuilderData?.schema?.properties !== undefined
      ) {
        this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.formBuilderData.schema)))];
        this.data = JSON.parse(JSON.stringify(this.formBuilderData.schema));
        // this.apiAdminBpmServices.sharedFormbuilder = JSON.parse(JSON.stringify(this.formBuilderData));
        // this.dataformbuilder.emit( JSON.parse(JSON.stringify(this.formBuilderData)))

        this.target = Event;
        this.target = this.data;

        const mapped = Object.keys(this.formBuilderData.schema.properties).map((key) => ({
          type: key,
          value: this.formBuilderData.schema.properties[key],
        }));
        this.dropZoneCreatedModel = [];
        console.log(mapped)
        for (let i = 0; i < mapped.length; i++) {
          if (
            mapped[i].value.typename === 'Texte' ||
            mapped[i].value.typename === 'string' ||
            mapped[i].value.typename === 'Text'
          ) {
            const textFormly = new TextFormly();
            textFormly.name = mapped[i].value.name;
            textFormly.default = mapped[i].value.default;
            textFormly.type = mapped[i].value.type;
            textFormly.title = mapped[i].value.title;
            textFormly.widget = mapped[i].value.widget;
            textFormly.format = mapped[i].value.format;
            textFormly.description = mapped[i].value.description;
            textFormly.icon = mapped[i].value.icon;
            textFormly.maxLength = mapped[i].value.maxLength;
            textFormly.minLength = mapped[i].value.minLength;
            textFormly.pattern = mapped[i].value.pattern;
            textFormly.autofill = mapped[i].value.autofill;
            textFormly.urlValidation = mapped[i].value.urlValidation;
            textFormly.typename = 'Text';
            this.dropZoneCreatedModel.push(textFormly);
          }
          if (mapped[i].value.typename === 'Json') {
            const jsonFormly = new JsonFormly();
            // Assign properties from mapped value
            jsonFormly.name = mapped[i].value.name;
            jsonFormly.type = mapped[i].value.type;
            jsonFormly.title = mapped[i].value.title;
            jsonFormly.description = mapped[i].value.description;
            jsonFormly.widget = mapped[i].value.widget;
            jsonFormly.format = mapped[i].value.format;
            jsonFormly.default = mapped[i].value.default;
            jsonFormly.icon = mapped[i].value.icon;
            jsonFormly.maxLength = mapped[i].value.maxLength;
            jsonFormly.minLength = mapped[i].value.minLength;
            jsonFormly.pattern = mapped[i].value.pattern;
            jsonFormly.autofill = mapped[i].value.autofill;
            jsonFormly.urlValidation = mapped[i].value.urlValidation;
            jsonFormly.typename = 'Json'; 
            this.dropZoneCreatedModel.push(jsonFormly);
          }
          if (mapped[i].value.typename === 'monetique') {
            const monetiqueFormly = new MonetiqueFormly();
            // Assign properties from mapped value
            monetiqueFormly.name = mapped[i].value.name;
            monetiqueFormly.type = mapped[i].value.type;
            monetiqueFormly.title = mapped[i].value.title;
            monetiqueFormly.icon = mapped[i].value.icon;
            monetiqueFormly.accDebit = mapped[i].value.accDebit;
            monetiqueFormly.accCredit = mapped[i].value.accCredit;
            monetiqueFormly.widget = mapped[i].value.widget;
            monetiqueFormly.typename = 'monetique'; 
            monetiqueFormly.description = mapped[i].value.description;
            monetiqueFormly.autofill = mapped[i].value.autofill;
            monetiqueFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            monetiqueFormly.urlValidation = mapped[i].value.urlValidation;
            monetiqueFormly.default = mapped[i].value.default;
            this.dropZoneCreatedModel.push(monetiqueFormly);
            
          }
          if (mapped[i].value.typename === 'Email') {
            const emailFormly = new EmailFormly();
            emailFormly.name = mapped[i].value.name;
            emailFormly.type = mapped[i].value.type;
            emailFormly.title = mapped[i].value.title;
            emailFormly.widget = mapped[i].value.widget;
            emailFormly.format = mapped[i].value.format;
            emailFormly.description = mapped[i].value.description;
            emailFormly.default = mapped[i].value.default;
            emailFormly.icon = mapped[i].value.icon;
            emailFormly.toggle = mapped[i].value.toggle;
            emailFormly.typename = 'Email';
            emailFormly.maxLength = mapped[i].value.maxLength;
            emailFormly.minLength = mapped[i].value.minLength;
            emailFormly.pattern =mapped[i].value.pattern;
            emailFormly.autofill = mapped[i].value.autofill;
            emailFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(emailFormly);
          }
          if (mapped[i].value.typename === 'Nombre' || mapped[i].value.type === 'number') {
            const numberFormly = new NumberFormly();
            numberFormly.name = mapped[i].value.name;
            numberFormly.type = mapped[i].value.type;
            numberFormly.title = mapped[i].value.title;
            numberFormly.widget = mapped[i].value.widget;
            numberFormly.maximum = mapped[i].value.maximum;
            numberFormly.minimum = mapped[i].value.minimum;
            numberFormly.description = mapped[i].value.description;
            numberFormly.icon = mapped[i].value.icon;
            numberFormly.default = mapped[i].value.default;
            numberFormly.toggle = mapped[i].value.toggle;
            numberFormly.typename = 'Number';
            numberFormly.exclusiveMinimum = mapped[i].value.exclusiveMinimum;
            numberFormly.exclusiveMaximum = mapped[i].value.exclusiveMaximum;
            numberFormly.pattern = mapped[i].value.pattern;
            numberFormly.autofill = mapped[i].value.autofill;
            numberFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            numberFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(numberFormly);
          }
          if (mapped[i].value.typename === 'Date') {
            const dateFormly = new DateFormly();
            dateFormly.name = mapped[i].value.name;
            dateFormly.type = mapped[i].value.type;
            dateFormly.title = mapped[i].value.title;
            dateFormly.widget = mapped[i].value.widget;
            dateFormly.format = mapped[i].value.format;
            dateFormly.default = mapped[i].value.default;
            dateFormly.description = mapped[i].value.description;
            dateFormly.typename = 'Date';
            dateFormly.toggle = mapped[i].value.toggle;
            dateFormly.icon = mapped[i].value.icon;
            dateFormly.pattern = mapped[i].value.pattern;
            dateFormly.autofill = mapped[i].value.autofill;
            dateFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            dateFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(dateFormly);
          }
          if (mapped[i].value.typename === 'Select' || mapped[i].type.includes('select')) {
            const selectFormly = new SelectFormly();
            selectFormly.name = mapped[i].value.name;
            selectFormly.type = mapped[i].value.type;
            selectFormly.default = mapped[i].value.default;
            selectFormly.description = mapped[i].value.description;
            selectFormly.typename = 'Select';
            selectFormly.title = mapped[i].value.title;
            selectFormly.widget = mapped[i].value.widget;
            selectFormly.pattern = mapped[i].value.pattern;
            selectFormly.autofill = mapped[i].value.autofill;
            selectFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            selectFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(selectFormly);
          }
          if (mapped[i].value.typename === 'Null Element') {
            const nullObjectFormly = new NullObjectFormly();
            nullObjectFormly.name = mapped[i].value.name;
            nullObjectFormly.type = mapped[i].value.type;
            nullObjectFormly.default = mapped[i].value.default;
            nullObjectFormly.title = mapped[i].value.title;
            nullObjectFormly.icon = mapped[i].value.icon;
            nullObjectFormly.typename = 'Null Element';
            nullObjectFormly.widget = mapped[i].value.widget;
            nullObjectFormly.pattern = mapped[i].value.pattern;
            nullObjectFormly.autofill = mapped[i].value.autofill;
            nullObjectFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            nullObjectFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(nullObjectFormly);
          }
          if (mapped[i].value.typename === 'Text Area') {
            const textAreaFormly = new TextAreaFormly();
            textAreaFormly.name = mapped[i].value.name;
            textAreaFormly.default = mapped[i].value.default;
            textAreaFormly.type = mapped[i].value.type;
            textAreaFormly.title = mapped[i].value.title;
            textAreaFormly.description = mapped[i].value.description;
            textAreaFormly.widget = mapped[i].value.widget;
            textAreaFormly.icon = mapped[i].value.icon;
            textAreaFormly.toggle = mapped[i].value.toggle;
            textAreaFormly.typename = 'Text Area';
            textAreaFormly.pattern = mapped[i].value.pattern;
            textAreaFormly.autofill = mapped[i].value.autofill;
            textAreaFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            textAreaFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(textAreaFormly);
          }

          if (mapped[i].value.typename === 'Check box') {
            const checkboxFormly = new CheckboxFormly();
            checkboxFormly.name = mapped[i].value.name;
            checkboxFormly.default = mapped[i].value.default;
            checkboxFormly.type = mapped[i].value.type;
            checkboxFormly.title = mapped[i].value.title;
            checkboxFormly.description=mapped[i].value.description
            checkboxFormly.icon = mapped[i].value.icon;
            checkboxFormly.toggle = mapped[i].value.toggle;
            checkboxFormly.typename = mapped[i].value.typename;
            checkboxFormly.widget = mapped[i].value.widget;
            checkboxFormly.pattern = mapped[i].value.pattern;
            checkboxFormly.autofill = mapped[i].value.autofill;
            checkboxFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            checkboxFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(checkboxFormly);
          }
          if (mapped[i].value.typename === 'Array') {
            const arrayFormly = new ArrayFormly();
            arrayFormly.name = mapped[i].value.name;
            arrayFormly.default = mapped[i].value.default;
            arrayFormly.type = mapped[i].value.type;
            arrayFormly.title = mapped[i].value.title;
            arrayFormly.description = mapped[i].value.description;
            arrayFormly.widget = mapped[i].value.widget;
            arrayFormly.typename = 'Array';
            arrayFormly.pattern = mapped[i].value.pattern;
            arrayFormly.autofill = mapped[i].value.autofill;
            arrayFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            arrayFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(arrayFormly);
          }
          if (mapped[i].value.typename === 'Time') {
            const timeFormly = new TimeFormly();
            timeFormly.name = mapped[i].value.name;
            timeFormly.default = mapped[i].value.default;
            timeFormly.type = mapped[i].value.type;
            timeFormly.title = mapped[i].value.title;
            timeFormly.description = mapped[i].value.description
            timeFormly.widget = mapped[i].value.widget;
            timeFormly.icon = mapped[i].value.icon;
            timeFormly.typename = 'Time';
            timeFormly.toggle = mapped[i].value.toggle;
            timeFormly.pattern = mapped[i].value.pattern;
            timeFormly.autofill = mapped[i].value.autofill;
            timeFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            timeFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(timeFormly);
          }
          if (mapped[i].value.typename === 'Multi Select') {
            const multiselectFormly = new MultiselectFormly();
            multiselectFormly.name = mapped[i].value.name;
            multiselectFormly.default = mapped[i].value.default;
            multiselectFormly.type = mapped[i].value.type;
            multiselectFormly.title = mapped[i].value.title;
            multiselectFormly.widget = mapped[i].value.widget;
            multiselectFormly.description = mapped[i].value.description;
            multiselectFormly.items = mapped[i].value.items;
            multiselectFormly.toggle = mapped[i].value.toggle;
            multiselectFormly.typename = 'Multi Select';
            multiselectFormly.pattern = mapped[i].value.pattern;
            multiselectFormly.autofill = mapped[i].value.autofill;
            multiselectFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            multiselectFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(multiselectFormly);
          }
          if (mapped[i].value.typename === 'Password') {
            const passwordFormly = new PasswordFormly();
            passwordFormly.name = mapped[i].value.name;
            passwordFormly.default = mapped[i].value.default;
            passwordFormly.type = mapped[i].value.type;
            passwordFormly.title = mapped[i].value.title;
            passwordFormly.format = mapped[i].value.format;
            passwordFormly.description = mapped[i].value.description;
            passwordFormly.maxLength = mapped[i].value.maxLength;
            passwordFormly.minLength = mapped[i].value.minLength;
            passwordFormly.typename = 'Password';
            passwordFormly.toggle = mapped[i].value.toggle;
            passwordFormly.pattern = mapped[i].value.pattern;
            passwordFormly.icon = mapped[i].value.icon;
            passwordFormly.widget = mapped[i].value.widget;
            passwordFormly.autofill = mapped[i].value.autofill;
            passwordFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            passwordFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(passwordFormly);
          }
          if (mapped[i].value.typename === 'Radio') {
            const radioFormly = new RadioFormly();
            radioFormly.name = mapped[i].value.name;
            radioFormly.type = mapped[i].value.type;
            radioFormly.default = mapped[i].value.default;
            radioFormly.description= mapped[i].value.description
            radioFormly.title = mapped[i].value.title;
            radioFormly.widget = mapped[i].value.widget;
            radioFormly.typename = 'Radio';
            radioFormly.pattern = mapped[i].value.pattern;
            radioFormly.autofill = mapped[i].value.autofill;
            radioFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
            radioFormly.urlValidation = mapped[i].value.urlValidation;
            this.dropZoneCreatedModel.push(radioFormly);
          }
        
        }

        // this.onChangeJson(this.target);
      }
      this.rebuildForm();
    }

    // else {
    /*      const listField = [];
      if (this.parentObject?.schema?.properties?.length > 0)
        this.parentObject?.schema?.properties?.forEach((element) => {
          listField.push(element);
        });
      if (this.formBuilderData?.schema?.properties?.length > 0)
        this.formBuilderData?.schema?.properties?.forEach((element) => {
          listField.push(element);
        });
      if (listField.length > 0) {
        this.formBuilderData.schema.properties = listField;
        this.onChangeJson(this.formBuilderData.schema);
      } */
    // }

    /* if (this.parentObject !== undefined) {
      this.parentObject?.schema?.properties?.forEach((element) => {
        element.disabled = true;
      });
    } */
    this.initOptionsList();
  }

  onChangeJson($event: any) {
    this.fields = [];
    this.fieldsCopie = [];
    // this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify($event)))];
    this.formModel = new FormModel();
    this.formModel.schema.properties = {};
    this.formModel.schema = $event;
    // this.dropZoneCreatedModel =$event;

    this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.formModel.schema)))];
    // this.apiAdminBpmServices.sharedFormbuilder = JSON.parse(JSON.stringify(this.formModel));
    this.dataformbuilder.emit(JSON.parse(JSON.stringify(this.formModel)));
  }

  private initOptionsList() {
    this.toDropElements = [];
    this.toDropElements.push(new TextFormly());
    this.toDropElements.push(new JsonFormly());
    this.toDropElements.push(new PasswordFormly());
    this.toDropElements.push(new EmailFormly());
    this.toDropElements.push(new TextAreaFormly());
    this.toDropElements.push(new NumberFormly());
    this.toDropElements.push(new RadioFormly());
    this.toDropElements.push(new DateFormly());
    this.toDropElements.push(new TimeFormly());
    this.toDropElements.push(new SelectFormly());
    this.toDropElements.push(new MultiselectFormly());
    this.toDropElements.push(new CheckboxFormly());
    this.toDropElements.push(new MonetiqueFormly());
  }

  drop(event: CdkDragDrop<any[]>) {
    this.dropZoneCreatedModel.forEach((element) => {
      if (element.name === 'idObject' && element.title === '') {
        this.alertWarning();
        this.obligText = false;
      }
    });
    if (this.obligText === true) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        this.initOptionsList();
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      }

      this.rebuildForm();
    }
  }

  dropElement() {
    let event: CdkDragDrop<any[]>;
    this.initOptionsList();
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

    this.rebuildForm();
  }

  /*
   * Delete element from drop zone by index
   * */
  deleteElementFromDropZoneList(i: number) {
    this.dropZoneCreatedModel.splice(i, 1);
    this.rebuildForm();
  }

  /*
   * drop event to test return element to main drop list
   * */
  noReturnPredicate() {
    return false;
  }

  saveFormModel() {
    this.formModel = new FormModel();
    this.formModel.schema.properties = {};
    this.dropZoneCreatedModel.forEach((x) => {
      this.formModel.schema.properties[x.name] = x;
    });

    this.dropElement();
  }

  onSubmit() {
    alert(JSON.stringify(this.model));
  }

  private rebuildForm() {
    this.fields = null;
    this.formModel = new FormModel();
    this.formModel.schema.properties = {};
    this.formModel.schema.properties = this.dropZoneCreatedModel;
    this.fieldsCopie = null;
    this.formModelCopie = new FormModel();
    this.formModelCopie.schema.properties = {};
    let mapped = null;
    if (this.parentObject?.schema?.properties) {
      mapped = Object.keys(this.parentObject?.schema?.properties).map((key) => ({
        type: key,
        value: this.parentObject.schema.properties[key],
      }));
    }

    this.dropZoneCreatedModelCopie = [];
    for (let i = 0; i < mapped?.length; i++) {
      if (
        mapped[i].value.typename === 'Texte' ||
        mapped[i].value.typename === 'string' ||
        mapped[i].value.typename === 'Text'
      ) {
        const textFormly = new TextFormly();
        textFormly.name = mapped[i].value.name;
        textFormly.default = mapped[i].value.default;
        textFormly.type = mapped[i].value.type;
        textFormly.title = mapped[i].value.title;
        textFormly.widget = mapped[i].value.widget;
        textFormly.format = mapped[i].value.format;
        textFormly.icon = mapped[i].value.icon;
        textFormly.description = mapped[i].value.description;
        textFormly.maxLength = mapped[i].value.maxLength;
        textFormly.minLength = mapped[i].value.minLength;
        textFormly.pattern = mapped[i].value.pattern;
        textFormly.autofill = mapped[i].value.autofill;
        textFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        textFormly.urlValidation = mapped[i].value.urlValidation;
        textFormly.typename = 'Text';
        this.dropZoneCreatedModelCopie.push(textFormly);
      }
      if (mapped[i].value.typename === 'Json') {
        const jsonFormly = new JsonFormly();
        // Assign properties from mapped value
        jsonFormly.name = mapped[i].value.name;
        jsonFormly.type = mapped[i].value.type;
        jsonFormly.title = mapped[i].value.title;
        jsonFormly.description = mapped[i].value.description;
        jsonFormly.widget = mapped[i].value.widget;
        jsonFormly.format = mapped[i].value.format;
        jsonFormly.default = mapped[i].value.default;
        jsonFormly.icon = mapped[i].value.icon;
        jsonFormly.maxLength = mapped[i].value.maxLength;
        jsonFormly.minLength = mapped[i].value.minLength;
        jsonFormly.pattern = mapped[i].value.pattern;
        jsonFormly.autofill = mapped[i].value.autofill;
        jsonFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        jsonFormly.urlValidation = mapped[i].value.urlValidation;
        jsonFormly.typename = 'Json'; // Assuming typename for JSON field
        this.dropZoneCreatedModelCopie.push(jsonFormly);
      }
      if (mapped[i].value.typename === 'monetique') {
        const monetiqueFormly = new MonetiqueFormly();
        // Assign properties from mapped value
        monetiqueFormly.name = mapped[i].value.name;
        monetiqueFormly.type = mapped[i].value.type;
        monetiqueFormly.title = mapped[i].value.title;
        monetiqueFormly.icon = mapped[i].value.icon;
        monetiqueFormly.accDebit = mapped[i].value.accDebit;
        monetiqueFormly.accCredit = mapped[i].value.accCredit;
        monetiqueFormly.widget = mapped[i].value.widget;
        monetiqueFormly.typename = 'monetique'; 
        monetiqueFormly.description = mapped[i].value.description;
        monetiqueFormly.autofill = mapped[i].value.autofill;
        monetiqueFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        monetiqueFormly.urlValidation = mapped[i].value.urlValidation;
        monetiqueFormly.default = mapped[i].value.default;
        this.dropZoneCreatedModel.push(monetiqueFormly);
      }
      if (mapped[i].value.typename === 'Email') {
        const emailFormly = new EmailFormly();
        emailFormly.name = mapped[i].value.name;
        emailFormly.type = mapped[i].value.type;
        emailFormly.title = mapped[i].value.title;
        emailFormly.description = mapped[i].value.description;
        emailFormly.widget = mapped[i].value.widget;
        emailFormly.format = mapped[i].value.format;
        emailFormly.default = mapped[i].value.default;
        emailFormly.icon = mapped[i].value.icon;
        // emailFormly.toggle = mapped[i].value.toggle;
        emailFormly.typename = 'Email';
        emailFormly.maxLength = mapped[i].value.maxLength;
        emailFormly.minLength = mapped[i].value.minLength;
        emailFormly.pattern = mapped[i].value.pattern;
        emailFormly.autofill = mapped[i].value.autofill;
        emailFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        emailFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(emailFormly);
      }
      if (mapped[i].value.typename === 'Nombre' || mapped[i].value.type === 'number') {
        const numberFormly = new NumberFormly();
        numberFormly.name = mapped[i].value.name;
        numberFormly.type = mapped[i].value.type;
        numberFormly.title = mapped[i].value.title;
        numberFormly.widget = mapped[i].value.widget;
        numberFormly.maximum = mapped[i].value.maximum;
        numberFormly.minimum = mapped[i].value.minimum;
        numberFormly.icon = mapped[i].value.icon;
        numberFormly.description = mapped[i].value.description;
        numberFormly.default = mapped[i].value.default;
        numberFormly.toggle = mapped[i].value.toggle;
        numberFormly.typename = 'Number';
        numberFormly.exclusiveMinimum = mapped[i].value.exclusiveMinimum;
        numberFormly.exclusiveMaximum = mapped[i].value.exclusiveMaximum;
        numberFormly.pattern =mapped[i].value.pattern;
        numberFormly.autofill = mapped[i].value.autofill;
        numberFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        numberFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(numberFormly);
      }
      if (mapped[i].value.typename === 'Date') {
        const dateFormly = new DateFormly();
        dateFormly.name = mapped[i].value.name;
        dateFormly.type = mapped[i].value.type;
        dateFormly.title = mapped[i].value.title;
        dateFormly.description = mapped[i].value.description;
        dateFormly.pattern = mapped[i].value.pattern;
        dateFormly.autofill = mapped[i].value.autofill;
        dateFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        dateFormly.urlValidation = mapped[i].value.urlValidation;
        dateFormly.widget = mapped[i].value.widget;
        dateFormly.format = mapped[i].value.format;
        dateFormly.default = mapped[i].value.default;
        dateFormly.typename = 'Date';
        dateFormly.toggle = mapped[i].value.toggle;
        dateFormly.icon = mapped[i].value.icon;
        this.dropZoneCreatedModelCopie.push(dateFormly);
      }
      if (mapped[i].value.typename === 'Select' || mapped[i].type.includes('select')) {
        const selectFormly = new SelectFormly();
        selectFormly.name = mapped[i].value.name;
        selectFormly.type = mapped[i].value.type;
        selectFormly.default = mapped[i].value.default;
        selectFormly.description = mapped[i].value.description;
        selectFormly.typename = 'Select';
        selectFormly.title = mapped[i].value.title;
        selectFormly.widget = mapped[i].value.widget;
        selectFormly.pattern = mapped[i].value.pattern;
        selectFormly.autofill = mapped[i].value.autofill;
        selectFormly.autofill = mapped[i].value.autofill;
        selectFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        this.dropZoneCreatedModelCopie.push(selectFormly);
      }
      if (mapped[i].value.typename === 'Null Element') {
        const nullObjectFormly = new NullObjectFormly();
        nullObjectFormly.name = mapped[i].value.name;
        nullObjectFormly.type = mapped[i].value.type;
        nullObjectFormly.default = mapped[i].value.default;
        nullObjectFormly.title = mapped[i].value.title;
        nullObjectFormly.icon = mapped[i].value.icon;
        nullObjectFormly.typename = 'Null Element';
        nullObjectFormly.widget = mapped[i].value.widget;
        nullObjectFormly.pattern = mapped[i].value.pattern;
        nullObjectFormly.autofill = mapped[i].value.autofill;
        nullObjectFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        nullObjectFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(nullObjectFormly);
      }
      if (mapped[i].value.typename === 'Text Area') {
        const textAreaFormly = new TextAreaFormly();
        textAreaFormly.name = mapped[i].value.name;
        textAreaFormly.default = mapped[i].value.default;
        textAreaFormly.type = mapped[i].value.type;
        textAreaFormly.title = mapped[i].value.title;
        textAreaFormly.description = mapped[i].value.description;
        textAreaFormly.widget = mapped[i].value.widget;
        textAreaFormly.icon = mapped[i].value.icon;
        textAreaFormly.toggle = mapped[i].value.toggle;
        textAreaFormly.typename = 'Text Area';
        textAreaFormly.pattern = mapped[i].value.pattern;
        textAreaFormly.autofill = mapped[i].value.autofill;
        textAreaFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        textAreaFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(textAreaFormly);
      }

      if (mapped[i].value.typename === 'Check box') {
        const checkboxFormly = new CheckboxFormly();
        checkboxFormly.name = mapped[i].value.name;
        checkboxFormly.default = mapped[i].value.default;
        checkboxFormly.type = mapped[i].value.type;
        checkboxFormly.description=mapped[i].value.description
        checkboxFormly.title = mapped[i].value.title;
        checkboxFormly.icon = mapped[i].value.icon;
        // checkboxFormly.toggle = mapped[i].value.toggle;
        checkboxFormly.typename = mapped[i].value.typename;
        checkboxFormly.widget = mapped[i].value.widget;
        checkboxFormly.pattern = mapped[i].value.pattern;
        checkboxFormly.autofill = mapped[i].value.autofill;
        checkboxFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        checkboxFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(checkboxFormly);
      }
      if (mapped[i].value.typename === 'Array') {
        const arrayFormly = new ArrayFormly();
        arrayFormly.name = mapped[i].value.name;
        arrayFormly.default = mapped[i].value.default;
        arrayFormly.type = mapped[i].value.type;
        arrayFormly.title = mapped[i].value.title;
        arrayFormly.description = mapped[i].value.description;
        arrayFormly.widget = mapped[i].value.widget;
        arrayFormly.typename = 'Array';
        arrayFormly.pattern = mapped[i].value.pattern;
        arrayFormly.autofill = mapped[i].value.autofill;
        arrayFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        arrayFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(arrayFormly);
      }
      if (mapped[i].value.typename === 'Time') {
        const timeFormly = new TimeFormly();
        timeFormly.name = mapped[i].value.name;
        timeFormly.default = mapped[i].value.default;
        timeFormly.type = mapped[i].value.type;
        timeFormly.title = mapped[i].value.title;
        timeFormly.description = mapped[i].value.description
        timeFormly.widget = mapped[i].value.widget;
        timeFormly.icon = mapped[i].value.icon;
        timeFormly.typename = 'Time';
        timeFormly.toggle = mapped[i].value.toggle;
        timeFormly.pattern = mapped[i].value.pattern;
        timeFormly.autofill = mapped[i].value.autofill;
        timeFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        timeFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(timeFormly);
      }
      if (mapped[i].value.typename === 'Multi Select') {
        const multiselectFormly = new MultiselectFormly();
        multiselectFormly.name = mapped[i].value.name;
        multiselectFormly.default = mapped[i].value.default;
        multiselectFormly.type = mapped[i].value.type;
        multiselectFormly.title = mapped[i].value.title;
        multiselectFormly.description = mapped[i].value.description;
        multiselectFormly.widget = mapped[i].value.widget;
        multiselectFormly.items = mapped[i].value.items;
        multiselectFormly.toggle = mapped[i].value.toggle;
        multiselectFormly.typename = 'Multi Select';
        multiselectFormly.pattern = mapped[i].value.pattern;
        multiselectFormly.autofill = mapped[i].value.autofill;
        multiselectFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        multiselectFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(multiselectFormly);
      }
      if (mapped[i].value.typename === 'Password') {
        const passwordFormly = new PasswordFormly();
        passwordFormly.name = mapped[i].value.name;
        passwordFormly.default = mapped[i].value.default;
        passwordFormly.type = mapped[i].value.type;
        passwordFormly.title = mapped[i].value.title;
        passwordFormly.format = mapped[i].value.format;
        passwordFormly.description = mapped[i].value.description;
        passwordFormly.maxLength = mapped[i].value.maxLength;
        passwordFormly.minLength = mapped[i].value.minLength;
        passwordFormly.typename = 'Password';
        passwordFormly.toggle = mapped[i].value.toggle;
        passwordFormly.icon = mapped[i].value.icon;
        passwordFormly.widget = mapped[i].value.widget;
        passwordFormly.pattern = mapped[i].value.pattern;
        passwordFormly.autofill = mapped[i].value.autofill;
        passwordFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        passwordFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(passwordFormly);
      }
      if (mapped[i].value.typename === 'Radio') {
        const radioFormly = new RadioFormly();
        radioFormly.name = mapped[i].value.name;
        radioFormly.type = mapped[i].value.type;
        radioFormly.description= mapped[i].value.description
        radioFormly.default = mapped[i].value.default;
        radioFormly.title = mapped[i].value.title;
        radioFormly.widget = mapped[i].value.widget;
        radioFormly.typename = 'Radio';
        radioFormly.pattern = mapped[i].value.pattern;
        radioFormly.autofill = mapped[i].value.autofill;
        radioFormly.urlIdentifiant = mapped[i].value.urlIdentifiant;
        radioFormly.urlValidation = mapped[i].value.urlValidation;
        this.dropZoneCreatedModelCopie.push(radioFormly);
      }
      // this.onChangeJson(this.formBuilderData.schema);
    }

    this.formModelCopie.schema.properties = this.dropZoneCreatedModelCopie;
    this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.formModel.schema)))];
    this.fieldsCopie = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.formModelCopie.schema)))];
    // this.apiAdminBpmServices.sharedFormbuilder = JSON.parse(JSON.stringify(this.formModel));
    this.dataformbuilder.emit(JSON.parse(JSON.stringify(this.formModel)));
  }

  alertWarning() {
    swal.fire({
      title: 'Erreur',
      text: "Le nom de l'identifiant de l'objet est obligatoire",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  copyToClipboard(): string {
    return JSON.stringify(this.formModel.schema);
  }

  onToggleView() {
    this.toggle_view = !this.toggle_view;
  }

  updateItem($event, i) {
    if ($event.name === 'idObject' && $event.title === '') {
      this.alertWarning();
      this.obligText = false;
      this.rebuildForm();
    } else {
      this.dropZoneCreatedModel[i] = $event;
      this.obligText = true;
      this.rebuildForm();
    }
  }

  getErrorParams($event) {
    this.itemerror.emit($event);
  }
}

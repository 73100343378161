import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
 
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { LigneProduit } from 'src/app/entity/Bpm/LigneProduits';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';
import { computeSegDraggable } from '@fullcalendar/core';
import { FormControl, Validators } from '@angular/forms';
import { L } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import { MatDialog } from '@angular/material/dialog';
import { ModalReassuranceComponent } from './modal-reassurance/modal-reassurance.component';

class ListeGroupGarantie {
  code_garantie: any;
  id_groupe: any;
  icon_garantie: any;
  nom_garantie: any;
  obligatoire: any;
  age_min : any;
  age_max : any
}
@Component({
  selector: 'app-group-garantie-shared',
  templateUrl: './group-garantie-shared.component.html',
  styleUrls: ['./group-garantie-shared.component.css'],
})
export class GroupGarantieSharedComponent implements OnInit {
  listeOfUnsubscriptionWebService: Subscription[] = [];
  public garantieAutoComplete$: Observable<any[]> = null;
  @ViewChild('closeModal') private closeModal: ElementRef;

  grantieListe: any;
  loaderListGarantie: boolean = false;
  loaderListReassuranceTypes: boolean = false;
  reassuranceTypesList: any[] = []; 

  @Input() id_contrat: string = '';
  @Input() id_gamme: string = '';
  @Input() listeGroupefromParent;
  ligneProd: LigneProduit = new LigneProduit();
  ListG: any[] = [];
  garantie: any;
  liste: any[] = [];
  codeListe: any;
  listeOfUnsubscribeWebService: Subscription[] = [];
  listgroup: ListeGroupGarantie[] = [];
  listeGroup: ListeGroupGarantie[] = [];
  affectergroup: FormControl = new FormControl('', {
    validators: [Validators.required],
    
  });

  codeGarantie: FormControl = new FormControl('', {
    validators: [Validators.required],
  });

  obligatoire: any = 0;
  champsvalid: boolean = false;
  @Output() listeGroupFrom = new EventEmitter<any>();
  @Output() listeGroupFromP = new EventEmitter<any>();
  @Output() validChamps = new EventEmitter<any>();
  @Input() garanties_herite;
  show_loading_adding: boolean;

  constructor(
    private apiGarantieService: ApiGarantieService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiGroupGarantieService: ApiGroupGarantieService,
    public dialog: MatDialog
  ) {}
 

  ngOnInit(): void {
   
    this.apiGroupGarantieService.getListGroupGarantie().subscribe((Response: any) => {
      this.garantie = Response;
    });
    this.listeGrantie();
    this.searchForgaranties();

    this.validChamps.emit(this.champsvalid);

    this.listeGroupFrom.emit(this.listgroup); 
  }

  openModal(idGarantie) {
   
    const filteredList = this.listeGroupefromParent.filter(item => item.code_garantie === idGarantie);
let mode:string;
    if (filteredList.length > 0) {
    if(filteredList[0].type!=null){
      mode='edit';
      }else{
        mode='add';
      }
    } else {
      mode='add';
    }
    const dialogRef = this.dialog.open(ModalReassuranceComponent, {
      width: '700px',
      height:'500px',
      data: { idGarantie: idGarantie,idGamme:this.id_gamme,mode:mode },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('Dialog result:', result);
    });
  }

  searchForgaranties() {
    this.searchgaranties = this.searchAddgarantie.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.type)),
      map((value) => {
        return this._filter(value);
      })
    );
  }

  _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();

    return this.grantieListe.filter((option) => {
      return (
        option.nom?.toLowerCase().indexOf(filterValue) > -1 || option.code?.toLowerCase().indexOf(filterValue) > -1
      );
    });
  }

  ngOnChanges() {
    // if(this.id_contrat !==''){
    // this.ListG = this.garanties_herite;
    //  this.ListG = this.listeGroupefromParent;
    // this.listgroup = this.listeGroupefromParent;

    this.ListG = this.garanties_herite;
    this.listgroup = this.listeGroupefromParent;
    this.listeGroupFromP.emit(this.ListG);
    this.listeGroupFrom.emit(this.listgroup);
  }

  grantieName: string;
  onSelectGarantie(item: any) {
    this.codeListe = [];
    const liste = new ListeGroupGarantie();
    liste.code_garantie = item.option.value.code;
    liste.id_groupe = '';
    liste.obligatoire = 0;
    liste.icon_garantie = item.option.value.icon;
    liste.nom_garantie = item.option.value.nom;

    // liste.icon_garantie =item.option.value.icon;
    for (const i of this.ListG) {
      this.codeListe.push(i.code_garantie, i.nom_garantie);
    }
    for (const i of this.listgroup) {
      this.codeListe.push(i.code_garantie, i.nom_garantie);
    }

    const index = this.codeListe.indexOf(item.option.value.code);
    if (index < 0) {
      this.listgroup.push(liste);
      this.listeGroupFrom.emit(this.listgroup);
    }

    // }
    // this.codeGarantie.setValue('')
  }
  
  removeModalBackdrop() {
    const backdropElements = document.querySelectorAll('.modal-backdrop.fade.show');
    backdropElements.forEach((element) => {
      element.classList.remove('modal-backdrop', 'fade', 'show');
    });
    document.body.style.overflow = 'auto';
  }

  listeGrantie() {
    const unsubscribeGetListGarantie = this.apiGarantieService.getListGarantie().subscribe((data) => {
      this.grantieListe = data;
      this.loaderListGarantie = true;
    });
    this.listeOfUnsubscriptionWebService.push(unsubscribeGetListGarantie);
  }

  getGarantieInfo(code, index) {
    this.listgroup.splice(index, 1);
    this.listeGroupFrom.emit(this.listgroup);
  }

  searchAddgarantie: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  searchgaranties: Observable<string[]>;

  onSelectGroup(item, i, index, x) {
    if (x === 'A') {
      this.listgroup[index].id_groupe = item.value;
      this.listeGroupFrom.emit(this.listgroup);
    } else {
      // this.ListG[index].id_groupe = item.value
      // const listeGaranteHerite = new ListeGroupGarantie();
      // listeGaranteHerite.code_garantie = this.ListG[index].code_garantie;
      // listeGaranteHerite.id_groupe = item.value;
      // listeGaranteHerite.icon_garantie = this.ListG[index].icon_garantie;
      // listeGaranteHerite.nom_garantie = this.ListG[index].nom_garantie;
      // listeGaranteHerite.obligatoire = this.obligatoire;
      // this.liste.push(listeGaranteHerite);
      this.ListG[index].id_groupe = item.value;
      // this.ListG[index].obligatoire = this.obligatoire;

      this.listeGroupFromP.emit(this.ListG);
    }
  }

  displayFn(grantie: any): string {
    return grantie ? grantie.code : '';
  }

  isActive(event, item) {
    if (event.target.checked) {
      this.obligatoire = 1;
    } else {
      this.obligatoire = 0;
    }
    item.obligatoire = this.obligatoire;
    this.obligatoire = this.listeGroupFromP.emit(this.ListG);
    this.listeGroupFrom.emit(this.listgroup);
  }

  valuechange(event, val ,index ){
if(val ==='age_min'){
this.listgroup[index].age_min = event.target.value;
}else{
  this.listgroup[index].age_max = event.target.value;
}
this.listeGroupFrom.emit(this.listgroup);
  }



  valuechangeH(event, val ,index ){
    if(val ==='age_min'){
    this.ListG[index].age_min = event.target.value;
    }else{
      this.ListG[index].age_max = event.target.value;
    }
    this.listeGroupFromP.emit(this.ListG);
      }

      numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    
      }
}
